.signup-navbar {
  height: 80px;
  width: 100%;
  /* border: 1px solid black; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.web-logo {
  /* border: 1px solid red; */
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sign-up {
  /* border: 1px solid green; */
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
  font-weight: 600;
  margin-right: 2em;
  color: #20303c;
}

.close-icon {
  /* border: 1px solid yellow; */
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1em;
}

.second-text-line {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #20303c;
  font-size: 16px;
}

.footer-text-line1 {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #20303c;
  font-size: 13px;
}

.footer-text-line2 {
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #20303c;
  font-size: 13px;
}

.link-login {
  color: #116dff !important;
  text-decoration: none !important;
}

.close-icon-link {
  text-decoration: none;
  color: #20303c;
}

.container-signup {
  display: flex;
  flex-wrap: wrap;
  padding: 50px 0px 0px 0px;
}

.column {
  flex: 1 0 50%; /* Set the width to 50% for equal columns */
  padding: 10px;
  box-sizing: border-box;
  border-right: 1px solid #c7c7ca;
  height: 440px;
  display: flex;
  justify-content: center;
}

.input-fields form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

.signup-platform {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

.google-signup {
  height: 48px;
  width: 300px;
  border: 1px solid #4285f4;
  display: flex;
  justify-content: center;
  align-items: center;
}

.google-logo {
  width: 20%;
  /* border: 1px solid white; */
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.google-png {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.linkedIn-png {
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.google-text {
  width: 80%;
  background: #4285f4;
  /* border: 1px solid red; */
  height: 100%;
  font-size: 15px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.signup-btn {
  /* border: 1px solid black; */
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
}

#signup-button {
  border-radius: 25px;
  height: 45px;
  width: 140px;
  color: #116dff;
  font-size: 15px;
  border: 1px solid #116dff;
  cursor: pointer;
  background: white;
}

#signup-button:hover {
  background: #116dff;
  color: white;
}

.main-div {
  background: white;
  margin: 0;
  padding: 0;
  min-height: 100vh;
}

.otp-field {
  display: flex;
}

.otp-field input {
  width: 45px;
  font-size: 32px;
  padding: 3px;
  text-align: center;
  border-radius: 5px;
  margin: 2px;
  border: 2px solid #55525c;
  background: #fff;
  color: #fff;
  outline: none;
  transition: all 0.1s;
  color: #000;
}

.otp-field input:focus {
  color: #000;
}

.disabled {
  opacity: 0.5;
}

.space {
  margin-right: 1rem !important;
}

.otp-name {
  font-size: 16px;
  text-align: left;
}

/* Plans selection */
b {
  padding: 0px 4px;
  display: inline-block;
}
.con-items {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10em;
}
.item {
  width: 360px;
  background: #fff;
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.05);
  border-radius: 40px;
  margin: 0px;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: all 0.25s ease;
  position: relative;
}
.item:not(.color):hover {
  transform: scale(1.05);
}
.item:hover .con-img {
  transform: scale(1.15);
}
.item.color:hover {
  transform: scale(1.15);
}
.con-img {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.25s ease;
}
.con-img img {
  width: 130px;
}
.item1 {
  padding-right: 45px;
}
.item2 {
  z-index: 100;
}
.item3 {
  padding-left: 45px;
}
.item.color {
  background: #3dcbab;
  color: #fff;
  transform: scale(1.1);
}
.item.color li {
  color: rgba(255, 255, 255, 0.75);
}
.item.color li b {
  color: rgba(255, 255, 255, 1);
}
.item.color li i {
  color: rgba(255, 255, 255, 1);
}
.item header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  width: 100%;
}
.item header h3 {
  font-size: 2rem;
}
.item header p {
  font-size: 1.2rem;
}
.badge {
  position: absolute;
  top: 20px;
  right: 20px;
  background: #fff;
  padding: 5px 10px;
  border-radius: 12px;
  color: rgb(61, 203, 171);
  font-weight: bold;
  font-size: 0.85rem;
}
.item ul {
  padding: 20px 0px;
  flex: 1;
  width: 100%;
}
.item ul li {
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  color: rgb(var(--color), 0.5);
}
.item ul li b {
  color: rgb(var(--color), 1);
}
.item ul li i {
  font-size: 1.6rem;
  margin-right: 15px;
  color: rgb(var(--color), 1);
}
.item button {
  padding: 14px 20px;
  width: 100%;
  background: rgb(61, 203, 171);
  border: 3px solid transparent;
  border-radius: 20px;
  color: #fff;
  font-weight: bold;
  font-size: 1.1rem;
  box-shadow: 0px 10px 25px 0px rgba(61, 203, 171, 0.35);
  cursor: pointer;
  transition: all 0.25s ease;
}
.item button:not(.border):hover {
  transform: translate(0, 5px);
  box-shadow: 0px 0px 0px 0px rgba(61, 203, 171, 0.35);
}
.item button.border {
  border: 3px solid #fff;
}
.item button.border:hover {
  background: #fff;
  color: rgb(61, 203, 171);
}
