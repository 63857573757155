.talk-to-us {
  padding: 4px;
  border-radius: 5px;
  border: 1px solid var(--darkTealColor);
  color: black;
  background: transparent;
}

.talk-to-us:hover {
  background-color: var(--darkTealColor);
  color: white;
}

.link-style {
  text-decoration: none !important;
  color: black !important;
  font-weight: 600;
}

.wrapper {
  max-width: 1090px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  justify-content: space-evenly;
  margin-top: 2em;
}

.wrapper .table {
  background: #fff;
  width: calc(33% - 20px);
  padding: 30px 30px;
  position: relative;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

@media (max-width: 1020px) {
  .wrapper .table {
    width: calc(50% - 20px);
    margin-bottom: 40px;
  }
}

@media (max-width: 698px) {
  .wrapper .table {
    width: 100%;
  }
}

.table .price-section {
  display: flex;
  justify-content: center;
}

.price-section .price-area {
  height: 120px;
  width: 120px;
  background: #ffd861;
  border-radius: 50%;
  padding: 2px;
}

.price-section .price-area .inner-area {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  border: 3px solid #fff;
  color: #fff;
  line-height: 117px;
  text-align: center;
  position: relative;
}

.price-area .inner-area .text {
  font-size: 25px;
  font-weight: 400;
  position: absolute;
  top: -10px;
  left: 17px;
}

.price-area .inner-area .price {
  font-size: 55px;
  font-weight: 500;
}

.table .package-name {
  width: 100%;
  height: 2px;
  background: #ffecb3;
  margin: 35px 0;
  position: relative;
}

.table .package-name::before {
  position: absolute;
  content: "Standard";
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  font-size: 25px;
  padding: 0 10px;
  font-weight: bolder;
}

.table .features li {
  list-style: none;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.features-flex {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  flex-direction: column;
  height: 56%;
}

.features li .list-name {
  font-size: 17px;
  font-weight: 400;
}

.features li .icon {
  font-size: 15px;
}

.features li .icon.check {
  color: #002060;
}

.features li .icon.cross {
  color: #cd3241;
}

.table .btn {
  display: flex;
  justify-content: center;
  margin-top: 35px;
}

.table .btn button {
  width: 80%;
  height: 50px;
  font-weight: 700;
  color: #fff;
  font-size: 20px;
  border: none;
  outline: none;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.basic .price-area,
.basic .inner-area {
  background: #ffd861;
}

.basic .btn button {
  background: #fff;
  color: #ffd861;
  border: 2px solid #ffd861;
}

.basic .btn button:hover {
  border-radius: 6px;
  background: #ffd861;
  color: #fff;
}

.Premium .price-area,
.Premium .inner-area {
  background: #a26bfa;
}

.Premium .btn button {
  background: #fff;
  color: #a26bfa;
  border: 2px solid #a26bfa;
}

.Premium .btn button:hover {
  border-radius: 6px;
  background: #a26bfa;
  color: #fff;
}

.Ultimate .price-area,
.Ultimate .inner-area {
  background: #002060;
}

.Ultimate .btn button {
  background: #fff;
  color: #002060;
  border: 2px solid #002060;
}

.Ultimate .btn button:hover {
  border-radius: 6px;
  background: #002060;
  color: #fff;
}

.price-range {
  color: white;
}

.basic .package-name {
  background: #ffecb3;
}

.Premium .package-name {
  background: #a26bfa;
}

.Ultimate .package-name {
  background: #002060;
}

.basic .package-name::before {
  content: "Standard";
}

.Premium .package-name::before {
  content: "Premium";
}

.Ultimate .package-name::before {
  content: "Pro";
}

.basic ::selection,
.basic .price-area,
.basic .inner-area {
  background: #ffd861;
}

.Premium ::selection,
.Premium .price-area,
.Premium .inner-area {
  background: #a26bfa;
}

.Ultimate ::selection,
.Ultimate .price-area,
.Ultimate .inner-area {
  background: #002060;
}

.text-headline {
  padding-bottom: 10px;
}

.subscription-text {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.icons-bg {
  background-color: green;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
