/* grid container */
.right-sidebar-grid {
  display: grid;
  grid-template-areas:
    "header"
    "main-content"
    "right-sidebar"
    "footer";
}

/* general column padding */
.right-sidebar-grid > * {
  padding: 1rem;
}

/* assign columns to grid areas */
.right-sidebar-grid > .header {
  grid-area: header;
  background: #f97171;
}
.right-sidebar-grid > .main-content {
  grid-area: main-content;
  background: #fff;
}
.right-sidebar-grid > .right-sidebar {
  grid-area: right-sidebar;
  background: #f5d55f;
}
.right-sidebar-grid > .footer {
  grid-area: footer;
  background: #72c2f1;
}

/* tablet breakpoint */
@media (min-width: 768px) {
  .right-sidebar-grid {
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas:
      "header header header"
      "main-content main-content right-sidebar"
      "footer footer footer";
  }
}

/* container */
.container-receipt {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background-color: white;
  position: relative;
  top: 23em;
  left: 10em;
  transform: translate(-50%, -50%);
  border: 1px solid #eee;
}

/* receipt_box */
.receipt_box > * {
  padding: 16px 32px;
}

/* head */
.head {
  display: flex;
  align-items: center;
}
.head .logo {
  flex: 1 0 30%;
}
.head .number {
  flex: 1 0 70%;
  color: slategray;
  font-size: 14px;
  line-height: 1.6;
}

/* body */
.body .info {
  margin-bottom: 24px;
  position: relative;
}
.body .info:before {
  content: "";
  display: block;
  width: 5px;
  height: 100%;
  background-color: mediumpurple;
  position: absolute;
  top: 0;
  left: -32px;
}
.body .info .welcome {
  margin-bottom: 8px;
  font-weight: bold;
}
.body .info .welcome .username {
  color: chocolate;
}
.body .info p {
  color: rosybrown;
  font-size: 14px;
}
.cart .title {
  margin-bottom: 16px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  text-transform: capitalize;
}
.cart .title:after {
  content: ":";
  display: inline-block;
  margin-left: 4px;
  margin-right: 4px;
}
.cart .content {
  font-size: 14px;
}

.cart_list {
  color: dimgray;
}
.cart_list .cart_item {
  display: flex;
  padding: 12px 0;
}
.cart_list .cart_item + .cart_item {
  border-top: 2px dashed #ccc;
}
.cart_list .cart_item .index {
  margin-right: 8px;
  width: 18px;
  overflow: hidden;
}
.cart_list .cart_item .name {
  flex-grow: 1;
}
.cart_list .cart_item .price {
  color: crimson;
  font-weight: bold;
}
.cart .total {
  padding: 12px 0;
  font-weight: bold;
  text-transform: uppercase;
  border-top: 2px solid darkorange;
}
.cart .total_price {
  float: right;
  color: crimson;
}

/* foot */
.foot {
  border-top: 2px dotted hotpink;
  position: relative;
}
.foot:before,
.foot:after {
  border: 4px solid transparent;
  position: absolute;
  top: -5px;
}
.foot:before {
  content: "";
  display: block;
  border-left: 7px solid hotpink;
  left: -1px;
}
.foot:after {
  content: "";
  display: block;
  border-right: 7px solid hotpink;
  right: -1px;
}
.foot img {
  width: 100%;
}
.continue-btn {
  width: 100%;
  height: 60px;
  background-color: var(--darkTealColor);
  color: white;
}
