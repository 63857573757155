nav {
  min-width: 100%;
  color: #000;
  display: flex!important;
  background-color: white;
  height: 100px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  /* border: 1px solid black; */
}

/*Styling logo*/
.logo {
  padding: 2vh 2vw;
  text-align: center;
}
.logo {
  font-size: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

/*Styling Links*/
.nav-links {
  display: flex;
  list-style: none;
  width: 85vw;
  padding: 0 0.7vw;
  justify-content: space-evenly;
  align-items: center;
  text-transform: uppercase;
  color: #000;
  /* border: 1px solid black; */
}

#btn-group {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  /* border: 1px solid black; */
  width: 25vw;
}

.nav-links #btn-group:last-child {
  display: flex;
  justify-content: flex-end !important;
  align-items: center;

  margin-right: -2em;
  /* border: 1px solid black; */
}

.nav-links li .link-css {
  text-decoration: none !important;
  margin: 0 0.7vw !important;
  color: #000 !important;
}
.nav-links li .link-css:hover {
  color: #61dafb !important;
}
.nav-links li {
  position: relative;
  color: #000;
  font-size: 14px;
  /* border: 1px solid black; */
}
.nav-links li .link-css::before {
  content: "" !important;
  display: block !important;
  height: 3px !important;
  width: 0% !important;
  /* background-color: #61dafb; */
  position: absolute !important;
  transition: all ease-in-out 250ms !important;
  margin: 0 0 0 10% !important;
}
.nav-links li .link-css:hover::before {
  width: 80% !important;
}

.btn-username {
  background: #002060;
  color: white;
}

/*Styling Buttons*/
.signin-button {
  background-color: transparent;
  border: 1.5px solid var(--lightTealColor);
  border-radius: 2em;
  padding: 0.5rem 0.7rem;
  font-size: 1rem;
  cursor: pointer;
  color: #000;
  width: 120px;
}
.signin-button:hover {
  color: #000 !important;
  transition: all ease-in-out 350ms;
  border-radius: 0.6em;
}
.join-button {
  color: #000;
  background-color: var(--lightTealColor);
  border: 1.5px solid var(--lightTealColor);
  border-radius: 2em;
  padding: 0.5rem 0.7rem;
  font-size: 1rem;
  cursor: pointer;
  width: 120px;
}
.join-button:hover {
  color: #000;
  transition: all ease-in-out 350ms;
  /* border-radius: 0; */
  border-radius: 0.6em;
}

/*Styling Hamburger Icon*/
.hamburger div {
  width: 30px;
  height: 3px;
  background: #000;
  margin: 5px;
  transition: all 0.3s ease;
}
.hamburger {
  display: none;
}

/*Stying for small screens*/
@media screen and (max-width: 800px) {
  nav {
    position: fixed;
    z-index: 3;
  }
  .hamburger {
    display: block;
    position: absolute;
    cursor: pointer;
    right: 5%;
    top: 50%;
    transform: translate(-5%, -50%);
    z-index: 2;
    transition: all 0.7s ease;
  }

  .nav-links {
    position: fixed;
    background: var(--lightTealColor);
    height: 100vh;
    width: 100%;
    flex-direction: column;
    clip-path: circle(50px at 90% -20%);
    -webkit-clip-path: circle(50px at 90% -10%);
    transition: all 1s ease-out;
    pointer-events: none;
    color: #000;
  }

  .nav-links.open {
    clip-path: circle(1000px at 90% -10%);
    -webkit-clip-path: circle(1000px at 90% -10%);
    pointer-events: all;
  }
  .nav-links li {
    opacity: 0;
  }
  .nav-links li:nth-child(1) {
    transition: all 0.5s ease 0.2s;
  }
  .nav-links li:nth-child(2) {
    transition: all 0.5s ease 0.4s;
  }
  .nav-links li:nth-child(3) {
    transition: all 0.5s ease 0.6s;
  }
  .nav-links li:nth-child(4) {
    transition: all 0.5s ease 0.7s;
  }
  .nav-links li:nth-child(5) {
    transition: all 0.5s ease 0.8s;
  }
  .nav-links li:nth-child(6) {
    transition: all 0.5s ease 0.9s;
    margin: 0;
  }
  .nav-links li:nth-child(7) {
    transition: all 0.5s ease 1s;
    margin: 0;
  }
  li.fade {
    opacity: 1;
  }
  .signin-button {
    background-color: transparent;
    border: 1.5px solid #000;
    border-radius: 0.5em;
    padding: 0.6rem 0.8rem;
    font-size: 1rem;
    cursor: pointer;
    color: #000;
    width: 120px;
  }
  .join-button {
    color: #fff;
    background-color: #000;
    border: 1.5px solid #000;
    border-radius: 0.6em;
    padding: 0.6rem 0.8rem;
    font-size: 1rem;
    cursor: pointer;
    width: 120px;
  }
}
/*Animating Hamburger Icon on Click*/
.toggle .line1 {
  transform: rotate(-45deg) translate(-5px, 6px);
}
.toggle .line2 {
  transition: all 0.7s ease;
  width: 0;
}
.toggle .line3 {
  transform: rotate(45deg) translate(-5px, -6px);
}

/* Dropdown menu */
#dropdown {
  color: #000;
  cursor: pointer;
  height: 50px;
  line-height: 50px;
  position: relative;
  /* width: 200px; */
  text-align: center;
  text-decoration: none;
  z-index: 1;
  /* padding: 0px 10px 0px 10px; */
  transform: perspective(1000px);
}

.dropdownMenubar {
  list-style-type: none;
  margin: 0;
  padding: 15px 0px 15px 0px;
  position: absolute;
  left: 0;
  opacity: 0;
  text-align: center;
  top: 0;
  visibility: hidden;
  z-index: -99999;
  height: 100%;
  border-radius: 0px 0px 15px 15px;
}

.dropdown-text {
  min-width: 150px;
  background: var(--lightTealColor);
  /* border: 1px solid black; */
}

.dropdownMenubar li:first-child {
  cursor: pointer;
  /* margin-top: 1em; */
}

.dropdownMenubar li:last-child {
  border-radius: 0px 0px 15px 15px;
}

.dropdownMenubar li {
  color: #fff;
  display: inline-block;
  width: 100%;
  text-decoration: none;
  -webkit-transition: all 1s;
  transition: all 1s;
  text-align: left;
  padding: 0px 0px 0px 10px;
}

.dropdownMenubar li:hover {
  background: #eee;
  color: #000;
}

#dropdown:hover {
  border-top: 2px solid var(--darkTealColor);
  /* border-bottom: 2px solid var(--darkTealColor);
  border-left: 2px solid var(--darkTealColor);
  border-radius: 10px 0px 0px 0px; */
}

#dropdown:hover .dropdownMenubar {
  /* background: linear-gradient(to top, var(--lightTealColor), #ffffff); */
  opacity: 1;
  visibility: visible;
  top: 100%;
  width: 100%;
  -webkit-transition: all 0.5s, background, 2s 0.5s linear;
  transition: all 0.5s, background 2s 0.5s linear;
}

.link-signup {
  text-decoration: none !important;
  color: #000 !important;
}
